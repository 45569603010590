:root {
  --bg: #080B1D;
  --white: #FFFFFF;
  --seafoam: #00E9BA;
  --seafoam-dark: #008B6F;
  --dark-blue: #6171D1;
  --darker-blue: #0F162C;
  --darkest-blue: #0A0918;
  --mirage: #0C162E;
  --magenta: #F800FF;
  --raspberry: #E12960;
  --salmon: #FBE2E9;
  --dark-blue-grey: #6171D1;
  --smoke: #BCBFCD;
  --dirty-white: #E9EAEE;

  --crab-class-surge: #FC712C;
  --crab-class-sunken: #123213;
  --crab-class-prime: #F7CF41;
  --crab-class-bulk: #79391A;
  --crab-class-craboid: #56A8C8;
  --crab-class-ruined: #8A8683;
  --crab-class-gem: #D25FA8;
  --crab-class-organic: #88A74D;
}
