h1.ant-typography,
h3.ant-typography {
  color: var(--white);
  margin-left: 0;
}

@font-face {
  font-family: 'punta';
  src: url('../fonts/punta-extraboldflat-webfont.woff2') format('woff2'),
       url('../fonts/punta-extraboldflat-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'punta';
  src: url('../fonts/punta-boldflat-webfont.woff2') format('woff2'),
       url('../fonts/punta-boldflat-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
