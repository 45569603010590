.site-layout.login {
  background-image: url('../../images/login-bg--mobile.png');
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  
  @include mq($from:desktop) {
    background-image: url('../../images/login-bg.png');
  }

  main {
    position: relative;
  }

  .loginWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  label::before {
    display: none !important;
  }

  form.has-errors {
    input[type=text],
    input[type=password] {
      border-color: var(--raspberry) !important;
      background-color: transparent !important;
    }

    label {
      color: var(--raspberry) !important;
    }
  }
  
  .bd-error {
    color: var(--salmon);
    display: block;
    margin-bottom: 24px;
    margin-top: -16px;
  }
}