.sidebar-menu,
.sidebar-menu-menu {
  background: linear-gradient(180deg, #091229 0%, #040C1E 100%);
  border-right: none;
}

.ant-menu-inline {
  padding: 0 16px;

  .ant-menu-item {
    font-family: 'punta';
    font-weight: 400;
    height: auto;
    margin-top: 0;
    padding: 18px 28px 14px 24px;

    span {
      line-height: 16px;
    }
  }

  .ant-menu-item-selected {
    background: linear-gradient(93.39deg, #00E9BA -5.31%, #00E9BA -5.3%, #F800FF 100%) !important;
    &::after {
      display: none;
    }
  }
}

.ant-menu-inline .ant-menu-item:not(:last-child) ,
.ant-menu-submenu {
  margin-bottom: 16px;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}

.ant-menu-submenu-selected {
  .ant-menu-submenu-title span {
    display: block;
    overflow: visible !important;
    
    &::after {
      border: 1px solid #63E7D2 !important;
      border-radius: 4px;
      content: "";
      display: block;
      height: 100%;
      left: -24px;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(100% + 24px);
    }
  }
}

.ant-menu-inline .ant-menu-submenu-title {
  background-position: top left;
  background-repeat: no-repeat;
  border-radius: 4px;
  color: var(--white);
  font-family: 'punta';
  font-size: 16px;
  font-weight: 800;
  justify-content: space-between;
  letter-spacing: 0.5px;
  overflow: visible;
  padding-right: 0;
  position: relative;

  &:hover {
    color: var(--white);
  }

  &::before,
  &::after {
    background: linear-gradient(93.39deg, #00E9BA -5.31%, #00E9BA -5.3%, #F800FF 100%);;
    border-radius: 4px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 0;
  }

  &::after {
    background: linear-gradient(90deg, #2C727C 5.3%, rgba(31, 41, 89, 0) 81.44%);
    mix-blend-mode: multiply;
    opacity: 1;
  }

  span {
    position: relative;
    z-index: 2;
  }
}

.axie-infinity .ant-menu-submenu-title {
  background-image: url('../images/sidebar/axie-infinity.png');
}

.crabada .ant-menu-submenu-title {
  background-image: url('../images/sidebar/crabada.png');
}

.ninneko .ant-menu-submenu-title {
  background-image: url('../images/sidebar/ninneko.png');
}

.crypto-unicorns .ant-menu-submenu-title {
  background-image: url('../images/sidebar/crypto-unicorns.png');
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow {
  background: linear-gradient(102.8deg, rgba(0, 233, 186, 0.68) -4.58%, rgba(33, 214, 138, 0.68) -4.57%, rgba(32, 97, 125, 0.68) 94.06%), rgba(65, 79, 149, 0.87);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  display: block;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(0);
  width: 48px;

  &::before,
  &::after {
    background-color: white;
    left: 50%;
    top: 50%;
    width: 12px;
  }

  &::before {
    transform: rotate(-45deg) translateX(0px) translateY(0px);
  }

  &::after {
    transform: rotate(45deg) translateX(-5px) translateY(5px);
  }
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: none;

  &::before {
    transform: rotate(45deg) translateX(0px) translateY(0px);
  }

  &::after {
    transform: rotate(-45deg) translateX(-5px) translateY(-5px);
  }
}

.ant-menu-submenu {
  ul {
    background: linear-gradient(118.35deg, #152743 3.94%, #0C162E 98.32%) !important;
    border: 1px solid rgba(191, 248, 239, 0.1) !important;
    border-radius: 4px !important;
    margin-top: 8px;
    padding: 8px 0 !important;
  }

  li {
    background-color: transparent !important;
    margin-bottom: 0 !important;
    padding: 30px 30px !important;
    height: auto !important;
  }

  li {
    padding-top: 18px !important;
  }

  li {
    padding-bottom: 18px !important;
  }
}

.ant-menu-item {
  &.--dashboard {
    background: linear-gradient(92.25deg, #2D3B7D 0%, #173C5F 100.71%) !important;
    border-radius: 4px;

    &::before {
      background-image: url('../images/sidebar/dashboard.png');
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 12px;
      margin-right: 24px;
      width: 12px;
    }
  }
}
