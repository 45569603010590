.ant-layout-content {
  margin: 0 40px !important;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed) {
  flex: 0 0 296px !important;
  max-width: 296px !important;
  width: 296px !important;
}

.site-layout {
  background-color: var(--bg);
}

.bd-logo {
  margin: 40px 0 128px;
  text-align: center;
}

body {
  background-color: var(--darkest-blue);
}
