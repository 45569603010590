.ant-input,
.ant-select-selector,
.ant-select-selection-search-input {
  border-color: var(--dark-blue) !important;
  border-radius: 4px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  height: auto !important;
  line-height: 22px;
  max-height: 56px;
  padding: 16px !important;
  max-width: 100%;

  &:focus {
    border-color: #6171D1;
  }
}

.ant-select {
  &:not(.ant-select-single) .ant-select-selector {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  &.ant-select-single .ant-select-selector {
    padding-left: 11px !important;
    padding-right: 11px !important;

    .ant-table-wrapper & {
      padding: 0px 5px !important;
    }
  }

  input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.ant-input-number-input-wrap input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-select-arrow svg {
  fill: var(--seafoam);
}

.ant-btn-lg {
  height: 56px;
  padding: 16px 20px;
}

// Slider
.ant-slider {
  margin-left: 0;

  &:hover .ant-slider-track:not(.ant-slider-track-1) {
    background-color: transparent;
  }
}

.ant-slider-rail {
  background-color: var(--dark-blue-grey);
  border-radius: 4px;
  height: 8px;
}

.ant-slider-track {
  background: transparent;
  border-radius: 4px;
  height: 8px;
}

.ant-slider-track-1 {
  background: linear-gradient(90.29deg, var(--seafoam) 3.92%, var(--seafoam-dark) 93.76%);
  border-radius: 4px;
  height: 8px;
}

.ant-slider-handle {
  background-color: var(--seafoam);
  border-color: var(--seafoam);
  height: 24px;
  margin-top: -8px;
  width: 24px;
}

.ant-input-group-addon {
  background-color: transparent;
}

.ant-input-group-wrapper.ant-input-search-large .ant-input-search-button {
  height: 56px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: normal;
}

.ant-select.ant-select-multiple {
  .ant-select-selector {
    max-height: none;

    .ant-select-selection-overflow-item-suffix .ant-select-selection-search-input {
      padding: 0 !important;
    }
  }

  .ant-select-clear {
    background-color: transparent;
  
    svg {
      fill: var(--dark-blue);
    }
  }
}

form:not(.ant-form-vertical) > .ant-row .ant-col.ant-form-item-label label,
:not(form) > div.ant-row > .ant-col.ant-form-item-label label {
  top: 50%;
  transform: translateY(-50%);
}

.ant-slider {
  margin-left: 12px;
  width: calc(100% - 24px) !important;
}

.ant-slider + table {
  color: var(--white);
  margin-left: -12px;
  width: calc(100% + 24px);

  td:not(.text-left):not(.text-right) {
    text-align: center;
  }
}

.fieldsWrapper {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    margin-right: 2.5%;
    width: 18%;

    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}

.actionWrapper {
  text-align: right;

  button {
    margin-left: 10px;
  }
}
