.fieldsWrapper {
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    width: 100%;
  
    & > div {
      margin-right: 2.5%;
      width: 18%;
  
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  
  .actionWrapper {
    text-align: right;
  
    button {
      margin-left: 10px;
    }
  }
  