@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Custom App CSS*/

.labels{
  padding: 0px;
  list-style: none;
}

.labels > li{
  padding-bottom: 10px;
  padding-top: 5px;
}

.ant-layout-sider-trigger
{
  background-color: #0F0F0F;
}
/*Loading Tables*/
.ant-spin-spinning
{
  opacity: 1; 
}

.ant-card-body
{
  padding: 16px;
  padding-bottom: 8px;
}

.ant-table
{
  background-color: transparent;
  color: inherit;
}

.ant-table-thead > tr > th
{
  color: inherit;
  font-weight: bold !important;
  background: transparent;
}

.ant-table-tbody > tr.ant-table-row:hover > td
{
    background: none !important;
}

tr.ant-table-row:nth-of-type(even)
{
  background: #0f0f0f !important; 
}

tr.ant-table-row
{
  cursor: pointer;
}

.ant-table-tbody > tr.ant-table-row{
    border: none !important;
}

/*Remove Table Underline*/
.ant-table-thead > tr > th
{
  border-bottom: 1px solid #394D66;
}

.ant-table-tbody > tr > td
{
  border-bottom: 0px solid #f0f0f0;
}


.table
{
  border: 1px solid #394D66;
}

.sidebar-menu, .sidebar-menu-menu
{
  color: white;
  background-color: #0F0F0F;
}

.title, .content-title, .table-title
{
  margin-top:  1em;

}
.title:first-letter {
    text-transform: uppercase;
}
a,.title, .content-title, .ant-result-404 > .ant-result-title, .ant-result-404 > .ant-result-subtitle
{
  color: white !important;
}

.content-title, .table-title
{
  margin-left: 1em;
}

.table-title
{
  color: #A3B2C6 !important;
}
.chartcard
{
  background-color:transparent;
}
.card-title
{
  color: #70829A;
}

.center-text
{
  text-align: center;
}

.ant-form-item-label > label
{
 color: white !important;
}
.site-layout
{
  overflow:'auto';
  color: white;
  background-color: #1A1A1A;
}
.ant-modal-footer
{
  border-top: unset;
  background-color: #262626;
}
.ant-input
{
  border-color: unset;
  outline: none;
  color: white;
  background-color: #1A1A1A;
}
.ant-input:focus{
  border-color: unset;
  outline: none;
  color: white;
  background-color: #1A1A1A;
}
.ant-drawer-content
{
  background-color:#262626;
}
.ant-drawer-header
{
  background-color:#262626;
  border-bottom: unset;
}
.ant-drawer-title
{
  color: white;
}
.ant-pagination-item-active{
  background-color:unset;
}
.ant-pagination-item{
  background-color:unset;
}
/*
.ant-drawer-header
{
  background-color:#181818;
}
.ant-drawer-title
{
  color: white;
}
*/
.no-item {
  background: #262626;
  padding: 0.75rem;
}

.ant-pagination-item-ellipsis {
    color: #fafafa !important;
    border-radius: 2px;
}

.ant-pagination-jump-next, .ant-pagination-jump-prev {
    border: 1px solid #fafafa !important;
    color: #fafafa !important;
}

.ant-select-selection2 {
  background-color: rgba(0, 0, 0, 0.85);
}

.ant-table-wrapper {
    padding: 10px;
}

.moveImg {
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-50px); }
}
@keyframes mover {
    0% { transform: translateY(0)}
    100% { transform: translateY(-50px); }
}

.black-text{
  color: #18233F;
  font-weight: bold;
}

.align-left {
  float: left;
}

.align-right {
  float: right;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.white {
  color: #FFFFFF;
}

.tbodyI {
  color: #FFFFFF;
  border-bottom: 1px solid #1F2959;
  border-right: 1px solid #1F2959;
  border-left: 1px solid #1F2959;
}

/* .tbodyI > tr{
  color: #FFFFFF;
  border-bottom: 1px solid #1F2959;
  border-right: 1px solid #1F2959;
  border-left: 1px solid #1F2959;
} */

.tbodyI > td{
  padding: 10px;
}

.tblInv .sndaryhead{
  background: #18233F;
  border-radius: 4px;
}

.tblPr .hasBg{
  background: #18233F;
  border-radius: 4px;
}

.tblPr .hasBg p{
  margin: 10px;
}

.tblInv .sndaryhead > td{
  color: #BCBFCD;
  padding: 10px;
}

.ant-table-thead th.ant-table-column-sort {
    background-color: unset;
}
td.ant-table-column-sort {
    background-color: unset;
}

.custom-btn-light {
  color: #fafafa;
  border: 1p solid fafafa;
}

.padding-space {
  padding: 30px;
  margin-bottom: 30px;
}

.padding-space-top {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.padding-top-bottom-20 { 
  padding-top:20px;
}

.padding-right { 
  padding-right:50px;
}

.margin-left { 
  margin-left:30px;
}

.margin-right { 
  margin-left:30px;
}

.color-saved {
  color: #FFB70F;
}

.color-red {
  color: #FF6767;
}

.custom-res-button{
  width: 328px;
  height: 56px;
  border: 1px solid #00E9BA;
  background: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 17px;
  float: right;
  margin-left: 20px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}
.custom-res-button2{
  width: 328px;
  height: 56px;
  border: 1px solid #00E9BA;
  background: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 17px;
  float: right;
  margin-left: 20px;
  cursor: pointer;
  text-align: center;
  line-height: 3;
}

.tblRes tr .hasBorder{
  padding: 10px;
  border-top: 1px solid #1F2959;
  border-bottom: 1px solid #1F2959;
  text-align: center;
}

.tblRes tr .hasBorderAll{
  padding: 20px;
  border: 1px solid #1F2959;
  text-align: center; 
} 

.colorProfit{
  color: #00E9BA;
}

.tblRes tr .hasBorderRight{
  border-top: 1px solid #1F2959;
  border-right: 1px solid #1F2959;
  text-align: center; 
} 

.tblRes tr .hasBorderRight{
  border-bottom: 1px solid #1F2959;
} 

.breed1{
  color:#E12960;
}
.breed2{
  color:#CE6B23;
}
.breed3{
  color:#00E9BA;;
}

.ant-tag {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.noBorder{
  padding: 20px;
  border: none;
  text-align: center;
}

.t12-padding {
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #333333;
}

.rbt-input-multi {
  border: 1px solid #4E4E4E;
  box-sizing: border-box;
  background: transparent;
  border-radius: 0%;
  color: #ffffff;
}

.rbt-token {
  background: #4E4E4E;
  font-family: Mina;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1em;
  color: #F1F4F8;
  border-radius: 0;
  margin-right: 8px;
}

.rbt-close {
  background: #4E4E4E;
  border: none;
  right: -0.2em !important;
  top: -0.0rem !important;
}

.rbt-aux .rbt-close {
  background: transparent;
  color: #fafafa;
}

.rbt-input-multi.focus {
  border: none !important;
  box-shadow: 0 0 0 0.1rem #4e4e4e !important;
}

.ant-select{
  background-color: transparent;
  color: #fff;
}

.ant-select2{
  background-color: transparent;
  color: #0F0F0F;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color: #fff;
}

.ant-result-title {
  color: #fff;
  /* color: rgba(0, 0, 0, 0.85); */
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
  justify-content: right;
  font-weight: bold;
}

/* .ant-select-custom-color > .ant-select-selection-item{
  background-color: transparent;
  color: #000;
} */
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  /* background: #f5f5f5; */
  background: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  /* color: rgba(0, 0, 0, 0.45); */
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  /* color: rgba(0, 0, 0, 0.25); */
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}

.padding-space {
  font-weight: bold;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  /* color: rgba(0, 0, 0, 0.85); */
  font-weight: 600;
  /* background-color: #e6f7ff; */
}

.ant-input {
  background: transparent;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* @import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

* {
    font-family: "Varela Round", sans-serif;
} */

/* body {
    background: #131212;
    color: white;
    overflow-x: hidden;
} */

.form-control:focus,
.form-control:active {
    border: none !important;
    box-shadow: none !important;
    outline: 0 none !important;
    background: transparent !important;
}

.full-container {
    max-width: 100%;
    padding: 0;
}

.short-container {
    max-width: 50rem;
}

.main-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100%);
}

.mh {
    max-height: calc(100% - 4.3rem) !important;
    height: 100%;
}

/* .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    width: 100%;
    right: 65px;
    bottom: 0;
} */

.custom-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    color: #b3b3b3;
}

.custom-label-default {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    color: #ffffff;
}

.custom-form-input {
    border: 1px solid #4e4e4e;
    box-sizing: border-box;
    border-radius: 0%;
    background-color: transparent;
    font-family: "Mina", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.custom-btn-default {
    background: linear-gradient(
        272.64deg,
        #3be0d6 0%,
        #35b1fc 33.61%,
        #4d94fd 66.95%,
        #6d6dff 100%
    );
    border-radius: 0%;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #ffffff;
    border: transparent;
}

.custom-btn-trans {
    border: 1px solid #4e4e4e;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border-radius: 0%;
}

.custom-btn-danger {
    background: linear-gradient(
        272.64deg,
        #e03b3b 0%,
        #fc3535 33.61%,
        #fd4d4d 66.95%,
        #ff6d6d 100%
    );
    border-radius: 0%;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #ffffff;
    border: transparent;
}

.custom-btn-primary {
    background: unset;
    border-radius: 0%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #ffffff;
    border: 1px solid #4E4E4E;
}

.custom-btn-option {
    background: #333333;
    border-radius: 0%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #ffffff;
    border: transparent;
}

.custom-btn-default:hover,
.custom-btn-trans:hover,
.custom-btn-danger:hover {
    color: #ffffff;
}

.nav-link:hover {
    color: #ffffff;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: transparent;
}


/********** REQUEST **********/
.request-no-item {
    font-family: "Mina";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}

.no-item {
    background: #262626;
    padding: 12px;
}

/********** TYPEAHEAD CLASS ICON *********/
.typeahead {
    padding-left: 2rem;
}

.typeahead.plant, .icon li.plant {
    background: url("./images/class/plant.png") left center no-repeat;
}

.typeahead.aqua, .typeahead.aquatic, .icon li.aqua, .icon li.aquatic {
    background: url("./images/class/aqua.png") left center no-repeat;
}

.typeahead.bird, .icon li.bird {
    background: url("./images/class/bird.png") left center no-repeat;
}

.typeahead.beast, .icon li.beast {
    background: url("./images/class/beast.png") left center no-repeat;
}

.typeahead.bug, .icon li.bug {
    background: url("./images/class/bug.png") left center no-repeat;
}

.typeahead.dawn, .icon li.dawn {
    background: url("./images/class/dawn.png") left center no-repeat;
}

.typeahead.dusk, .icon li.dusk {
    background: url("./images/class/dusk.png") left center no-repeat;
}

.typeahead.mech, .icon li.mech {
    background: url("./images/class/mech.png") left center no-repeat;
}

.typeahead.reptile, .icon li.reptile {
    background: url("./images/class/reptile.png") left center no-repeat;
}

