.bar span {
  background-color: #18233f;
  border-color: #18233f;
  color: var(--white);
  min-width: 110px;
}

.midwayTable {
  tbody > tr:not(:first-child):hover .hl {
    background-color: #0e1121;
  }
}

.hlP:hover {
  .hlC {
    background-color: #0e1121;
  }
}
