.headline {
  background-color: #080B1D;
  border-bottom: solid 1px #1E2959;
  margin: 0 -40px 48px;
  padding: 32px 40px 38px;
  position: relative;

  h1 {
    background: linear-gradient(93.39deg, #00E9BA -5.31%, #00E9BA -5.3%, #F800FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    display: inline-block;
    font-size: 40px;
    font-family: 'punta', 'Arial';
    line-height: 42px;
    margin-bottom: 1px;
  }

  p {
    margin-left: 2px;
    margin-bottom: 0;
  }

  [class^="ant-col"]:last-child,
  [class*=" ant-col"]:last-child {
    display: flex;
    align-items: center;
    justify-content: right;

    & > * {
      position: relative;
    }

    & > *:not(:last-child) {
      margin-right: 32px;
    }

    [class^="il-btn"],
    [class*=" il-btn"] {
      font-size: 16px;
      line-height: 13.92px;
    }

    [class^="il-btn--gradient"],
    [class*=" il-btn--gradient"] {
      &::before {
        border-right: 15px solid #080B1D; // Outside
        border-top: 15px solid #080B1D; // Inside
      }

      span {
        background-color: #080B1D;
      }
    }
  }
}