.btn-info {
  background: linear-gradient(93.39deg, #6171D1 -5.31%, #00E9BA 100%);
  font-family: 'Nunito Sans', sans-serif;
}

.btn-main {
  background: linear-gradient(93.39deg, #00E9BA -5.31%, #00E9BA -5.3%, #F800FF 100%);
  border-radius: 4px;
  font-family: 'Nunito Sans', sans-serif;
}

.ant-btn-lg {
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
}

.il-btn {
  border-radius: 2px;
  height: auto;
  padding: 13px 21px;

  &--danger {
    background: transparent;
    border: solid 2px var(--raspberry);
    color: var(--raspberry) !important;
    transition: all 300ms ease;

    &:hover {
      background-color: var(--raspberry);
      color: var(--white) !important;
    }
  }

  &--gradient {
    background-image: linear-gradient(93.39deg, #6171D1 -5.31%, #00E9BA 100%);
    border-radius: 2px;
    box-sizing: border-box;
    color: #00a84f;
    display: block;
    margin: 0 auto;
    padding: 2px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 2;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   bottom: 0; right: 0;
    //   border-right: 15px solid #1A1A1A; // Outside
    //   border-top: 15px solid #1A1A1A; // Inside
    //   width: 0;
    // }

    span {
      align-items: center;
      background: #1A1A1A;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      height: 100%;
      transition: background .5s ease;
      padding: 14px 32px 14px 16px;
      width: 100%;
    }
  }
}