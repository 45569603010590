h3 {
  font-family: 'punta', Arial, Helvetica, sans-serif
}

.resultWrapper {
  border-top: 1px solid #1F2959;
  margin-top: 24px;
  padding-top: 64px;

  .cardContainer {
    margin-bottom: 48px;
  }

  .crabCard {
    background: linear-gradient(98.18deg, rgba(97, 113, 209, 0.15) 2.58%, rgba(248, 165, 255, 0.15) 100.51%);
    border: solid 1px #00E9BA;
    color: var(--white);
    margin-top: 15px;
    
    & > div {
      display: flex;
      justify-content: space-sart;
      padding: 19px 16px 19px 13px;
    }

    .crabPreview {
      margin-right: 28px;

      img {
        max-height: 86px;
        max-width: 100%;
      }
    }

    .crabInfo {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 28px - 88px);

      span {
        display: inline-block;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: bold;
        line-height: 16px;
        margin-bottom: 16px;
        text-transform: uppercase;

        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }

        &:nth-child(odd) {
          font-size: 12px;
          width: 70%;
        }

        &:nth-child(even) {
          font-size: 14px;
          text-align: right;
          text-transform: capitalize;
          width: 25%;
        }

        &.crabClass {
          &--surge {
            color: var(--crab-class-surge);
          }
          &--sunken {
            color: var(--crab-class-sunken);
          }
          &--prime {
            color: var(--crab-class-prime);
          }
          &--bulk {
            color: var(--crab-class-bulk);
          }
          &--craboid {
            color: var(--crab-class-craboid);
          }
          &--ruined {
            color: var(--crab-class-ruined);
          }
          &--gem {
            color: var(--crab-class-gem);
          }
          &--organic {
            color: var(--crab-class-organic);
          }
        }
      }
    }
  }

  h3 {
    margin-bottom: 17px;
  }

  h4 {
    color: var(--smoke);
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
  }

  .classLegend {
    background-color: transparent;
    color: var(--dirty-white);
    display: flex;
    flex-wrap: wrap;

    & > div {
      padding: 0;
    }
  }

  .infoCard {
    background-color: transparent;
    border-color: #1F2959;
    height: 100%;

    & > div {
      color: var(--dirty-white);

      // Card Title
      &:first-child {
        background: linear-gradient(270deg, #0F142D 0%, #353E6A 50.63%, #0F142D 100%) left bottom no-repeat;
        background-size:100% 2px ;
        border-bottom: none;

        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;

        padding: 0 16px;
        text-transform: uppercase;

        h4 {
          position: relative;
        }

        h4 svg {
          display: block;
          height: 24px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
        }
      }
    }

    span:nth-child(even) {
      text-align: right;
    }
  }

  .classLegend,
  .infoCard {
    span {
      display: inline-block;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      height: 16px;
      margin-bottom: 20px;
      width: 49%;

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }

      &:nth-child(odd) {
        margin-right: 2%;
      }

      i {
        border: solid 1px var(--white);
        border-radius: 50%;
        display: inline-block;
        height: 16px;
        margin-right: 8px;
        transform: translateY(3px);
        width: 16px;
      }

      &.surge i {
        background-color: var(--crab-class-surge);
      }
      &.sunken i {
        background-color: var(--crab-class-sunken);
      }
      &.prime i {
        background-color: var(--crab-class-prime);
      }
      &.bulk i {
        background-color: var(--crab-class-bulk);
      }
      &.craboid i {
        background-color: var(--crab-class-craboid);
      }
      &.ruined i {
        background-color: var(--crab-class-ruined);
      }
      &.gem i {
        background-color: var(--crab-class-gem);
      }
      &.organic i {
        background-color: var(--crab-class-organic);
      }
    }
  }
}